import baseService from '../baseService';
const request = baseService('statistics');

export default {
  getAccessStatistics(params) {
    return request('/getAccessStatistics', params, 'get');
  },
  getProductStatistics(params) {
    return request('/getProductStatistics', params, 'get');
  },
  getProductCollects(params) {
    return request('/getProductCollects', params, 'get');
  }
};
