<template>
  <div class="echarts" ref="echarts"></div>
</template>

<script>
  import * as echarts from 'echarts';
  import { listenerPolicy } from '@/mixins';

  export default {
    name: 'echarts',
    mixins: [listenerPolicy],
    props: {
      config: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        echarts: null
      };
    },
    mounted() {
      this.mixin_listenerPolicy_addListener(this.$el, 'resizeObserver', () => {
        this.echarts.resize();
      });
      this.echarts = echarts.init(this.$refs.echarts);
      this.echarts.setOption(this.config);
    }
  };
</script>

<style scoped>
  .echarts {
    width: 100%;
    height: 100%;
  }
</style>
